import { Datepicker } from 'vanillajs-datepicker';
import nl from 'vanillajs-datepicker/js/i18n/locales/nl';
import fr from 'vanillajs-datepicker/js/i18n/locales/fr';

Object.assign(Datepicker.locales, nl, fr);

const now = new Date();
const elem = document.querySelector('input[name="date"]');
const datepicker = new Datepicker(elem, {
    'format': 'dd/mm/yyyy',
    'language': siteConf.lang,
    'minDate': now.setDate(now.getDate() + 1)
});
